.App {
   text-align: center;  

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
  --amplify-primary-color:	#299891;
  --amplify-primary-contrast:	var(–amplify-white);
  --amplify-primary-tint:	#299891;
  --amplify-primary-shade:	#299891;
  --amplify-secondary-color:	white;
  --amplify-secondary-contrast:	transparent;
  --amplify-secondary-tint:	#299891;
  --amplify-secondary-shade:	#1F2A37;
  --amplify-tertiary-color:	#006C78;
  --amplify-tertiary-contrast:	var(–amplify-white);
  --amplify-tertiary-tint:	#006C78;
  --amplify-tertiary-shade:	#006C78;
  --amplify-grey: white;
  --amplify-light-grey:	#c4c4c4;
  --amplify-white:	#ffffff;
  --amplify-red:	#dd3f5b;
  --amplify-background-color: hsl(193, 39%, 18%, 0.3)
}

.splitter-layout{
  max-width: 95% !important
  }